

































import Vue from 'vue'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, firestore } from '../../main'

export default Vue.extend({
  name: 'update-phone-dialog',
  props: ['useremail'],
  data: () => ({
    dialog: false,
    loading: false,
    phonenumber: ''
  }),
  methods: {
    async updateAccountPhoneNumber () {
      const user = auth.currentUser?.email
      this.loading = true

      if (user === undefined || user === null) {
        return
      }

      const accountref = doc(firestore, 'users', user)

      await updateDoc(accountref, {
        phone_number: this.phonenumber
      })

      this.loading = false
      this.dialog = false
    }
  }
})
