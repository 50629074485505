


















import AccountInfo from '@/components/AccountInfo.vue'
import Vue from 'vue'

export default Vue.extend({
  name: 'dashboard-view',

  components: {
    AccountInfo

  }
})
