



































import Vue from 'vue'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, firestore } from '../main'
import UpdatePhoneNumber from './dialogs/UpdatePhoneNumber.vue'

export default Vue.extend({
  name: 'account-info',
  components: {
    UpdatePhoneNumber

  },
  data: () => ({
    email: '',
    phonenumber: '',
    notification_switch: false

  }),
  created () {
    this.getAccountInfo()
  },
  methods: {
    async getAccountInfo () {
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const accountref = doc(firestore, 'users', user)
      const accountSnapshot = await getDoc(accountref)

      this.email = accountSnapshot.get('email')
      this.phonenumber = accountSnapshot.get('phone_number')
      this.notification_switch = accountSnapshot.get('sms_notification')

    //   context.loaded = true
    },
    async changeNotificationSettings (test: any) {
      console.log(this.notification_switch)
      const user = auth.currentUser?.email

      if (user === undefined || user === null) {
        return
      }

      const accountref = doc(firestore, 'users', user)

      await updateDoc(accountref, {
        sms_notification: this.notification_switch
      })
    }
  }
})
